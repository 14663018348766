<template>
    <div>
        <v-toolbar color="green" dark dense>
            <v-toolbar-title>
                Procedimientosy actividades
            </v-toolbar-title>

            <v-spacer/>

            <v-btn text @click="cargaDatos">
                <v-icon>mdi-sync</v-icon>
            </v-btn>
            <v-btn class="primary" rounded @click="dialogNuevo=true">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-toolbar>
        <div class="text-center mt-3 text-h6">Tiempo invertido en este paciente (minutos)</div>
        <div class="d-flex flex-row justify-space-around pt-3">
            <div class="text-center blue--text">Matutino<br/>{{tiempoMat}}</div>
            <div class="text-center green--text">Vespertino<br/>{{tiempoVes}}</div>
            <div class="text-center red--text">Nocturno<br/>{{tiempoNoc}}</div>
            <div class="text-center font-weight-black">Total del día<br/>{{tiempoTot}}</div>
        </div>

        <v-data-table
            :headers="headers"
            :items="procTodos"
            disable-sort
            disable-pagination
            hide-default-footer
            :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            
            item-key="id"
            :loading="loadingTodos"

            :server-items-length='total'
            :options.sync="optionsTable"

        >
            <template v-slot:[`item.procedimientoNombre`]="{ item }">
                <span v-if="item.turno == 'mat'" class="blue--text">{{item.procedimientoNombre}}</span>
                <span v-if="item.turno == 'ves'" class="green--text">{{item.procedimientoNombre}}</span>
                <span v-if="item.turno == 'noc'" class="red--text">{{item.procedimientoNombre}}</span>
            </template>
            <template v-slot:[`item.detalles`]="{ item }">
                <v-btn icon @click="cargaDetalles(item)">
                    <v-icon>mdi-magnify</v-icon>
                </v-btn>
                <v-btn icon @click="borraEntrada(item.id)" v-if="tieneRol('ROLE_ENFERMERIA_ADMIN')||tieneRol('ROLE_ADMIN')">
                    <v-icon color="red">mdi-eraser</v-icon>
                </v-btn>
            </template>
            
        </v-data-table>
        
        <nuevo-proc
            :dialogNuevo.sync="dialogNuevo"
            :inter="internamiento"
            :estaFecha="estaFecha"
            @guardado="cargaDatos"
        />

    </div>
</template>

<script>
export default {
    mounted(){
        this.cargaDatos()
    },
    props:{
        internamiento:Number,
        estaFecha:String,
    },
    components:{
        'nuevo-proc': () => import('@/components/hospital/enfe/procedimientos/NuevoProcEnf'),
    },
    data:()=>({
        headers: [
            { text: 'Procedimiento', value: 'procedimientoNombre', width:'25%'},
            { text: 'Duración', value: 'tiempo', align:'center', width:'5%'},
            { text: 'Observaciones', value: 'observaciones', width:'25%'},
            { text: '', value: 'detalles', align:'center', width:'10%'},
        ],
        procTodos:[],
        loadingTodos:false,
        total:0,
        optionsTable:{},

        dialogNuevo:false,
    }),

    methods:{
        borraEntrada(id){
            //let motivo 
            this.$swal.fire({
                title: 'Borrar esta entrada',
                text: 'Motivo para borrar esta entrada',
                input: 'text',
                //inputValue:motivo,
                confirmButtonText: 'Borrar registro',
                showLoaderOnConformation: true,
                inputValidator:  (value) => {
                    if (value) {
                        this.$swal.fire({
                            title:'¿Desea borrar?',
                            text:'Esta acclón no se puede deshacer',
                            icon: 'warning',
                            showCancelButton: true
                        }).then((result)=>{
                            if (result.value){
                                this.borra2(id,value)
                            }
                        })
                    } else {
                        return 'Indique el motivo para esta eliminación'
                    }
                }
            })
        },
        async borra2(id,motivo){
            try{
                let borra = await this.$http({
                    method:'DELETE',
                    url:'/enfermeria/borraProcs',
                    data:{id:id, motivo:motivo}
                })
                if (borra.data.estado === true){
                    this.cargaDatos()
                } else {
                    console.log(borra.data)
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede eliminar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
                
            } catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },
        async cargaDatos(){
            this.loadingTodos=true
            try{
                let req = await this.$http({
                    url:'/enfermeria/procedimientosList2',
                    method:'GET',
                    params:{internamiento:this.internamiento, fecha:this.estaFecha}
                })
                this.loadingTodos=false
                this.procTodos = req.data.root
                this.total = req.data.total
                //console.log(this.procTodos)
            }catch(err){
                this.loadingTodos=false
                this.$store.dispatch('security/revisaError',err)
            }
        },
        cargaDetalles(itm){
            this.$swal.fire({
                text: 'Registrado el '+itm.dateCreated+' por '+itm.creado,
            })
        },

        tieneRol(rol){
            let contiene = this.$store.state.security.roles.includes(rol)
            return (contiene)
        },

    },

    computed:{
        tiempoMat(){
            let total = this.procTodos.filter(itm=>itm.turno=='mat').map(a => a.tiempo).reduce((a, b) => a + b, 0)
            return total
        },
        tiempoVes(){
            let total = this.procTodos.filter(itm=>itm.turno=='ves').map(a => a.tiempo).reduce((a, b) => a + b, 0)
            return total
        },
        tiempoNoc(){
            let total = this.procTodos.filter(itm=>itm.turno=='noc').map(a => a.tiempo).reduce((a, b) => a + b, 0)
            return total
        },
        tiempoTot(){
            let total = this.procTodos.map(a => a.tiempo).reduce((a, b) => a + b, 0)
            return total
        },
    },
    watch:{
        estaFecha(){
            this.cargaDatos()
        },
    },
}
</script>

<style>

</style>